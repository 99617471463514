<template>
  <div id="app">
    <pageHeader />
    <div>
      <router-view></router-view>
    </div>
    <footer v-if="$route.meta.isFooter">
      <div class="footetr-bac">
        <div class="page-title">{{$route.meta.footerTitle|| 'AI算法助力人才管理，立即抢占先机'}}</div>
        <div class="foo-text">欢迎联系我们，华数云AI助力企业高效工作</div>

        <router-link
          to="/applyFor"
          class="foo-btn"
        >联系我们</router-link>
        <!-- <a
          class="foo-btn"
          target="_blank"
          href="https://rccp-api.huashuyunai.com/ent/#/passport/login?redirect=%2Findex"
        >联系我们</a> -->
        <div class="foo-number">全国热线：166-2016-5017</div>
      </div>
      <div class="footer">
        <div class="page-wrap">
          <div class="footer-wrap">
            <div class="left">
              <div class="item">
                <div class="label">华数云</div>
                <router-link
                  to="/about?id=intro"
                  class="text"
                >公司简介</router-link>
                <router-link
                  to="/about?id=steps"
                  class="text"
                >发展历程</router-link>
                <router-link
                  to="/about?id=certificate"
                  class="text"
                >资质专利</router-link>
              </div>
              <div class="item">
                <div class="label">产品服务</div>
                <router-link
                  to="/productService"
                  class="text"
                >人才评定
                </router-link>
                <router-link
                  to="/productService"
                  class="text"
                >人才能力</router-link>
                <a
                  class="text"
                  target="_blank"
                  href="https://rccp-api.huashuyunai.com/school/#/passport/login?redirect=%2Findex"
                >

                  学校后台
                </a>
                <a
                  class="text"
                  target="_blank"
                  href="https://rccp-api.huashuyunai.com/ent/#/passport/login?redirect=%2Findex"
                >

                  企业后台</a>
              </div>
              <div class="item">
                <div class="label">场景解决方案</div>
                <router-link
                  to="/community"
                  class="text"
                >
                  社会招聘
                </router-link>
                <router-link
                  to="/jobAdjustment"
                  class="text"
                  slot="reference"
                >
                  岗位调整
                </router-link>
                <router-link
                  to="/topManager"
                  class="text"
                  slot="reference"
                >
                  中高管招聘
                </router-link>
                <router-link
                  to="/solution"
                  class="text"
                  slot="reference"
                >
                  内部选拔晋升
                </router-link>
                <router-link
                  to="/jobFair"
                  class="text"
                  slot="reference"
                >
                  招聘会
                </router-link>
              </div>
            </div>
            <div class="right">
              <div class="code-item">
                <div class="code-label">人才评定</div>
                <img
                  class="code-img"
                  src="../src/assets/img/code.png"
                  alt=""
                >
              </div>
              <div class="code-item">
                <div class="code-label">华数云官方</div>
                <img
                  class="code-img"
                  src="../src/assets/img/code-1.png"
                  alt=""
                >
              </div>
            </div>
          </div>
          <div class="copyright">

            <img
              src="./assets/img/footer-logo.png"
              alt="logo"
            >
            <div class="lanel">
              <span class="lanel-text">Copyright © 2023 . All Rights Reserved. 广州华数云计算有限公司站</span>
              <span class="lanel-text">京ICP备16045432号-4</span>
              <span class="lanel-text">粤公网安备44011702000694号</span>
            </div>
          </div>
        </div>
      </div>
      <div class="h5-footer">
        <img
          src="./assets/img/footer-logo.png"
          alt="logo"
          class="logo-img"
        >
        <div class="footer-wrap">
          <div class="left">
            <div class="item">
              <div class="label">华数云</div>
              <router-link
                to="/about?id=intro"
                class="text"
              >公司简介</router-link>
              <router-link
                to="/about"
                class="text"
              >发展历程</router-link>
              <router-link
                to="/about"
                class="text"
              >资质专利</router-link>
            </div>
            <div class="item">
              <div class="label">产品服务</div>
              <router-link
                to="/productService"
                class="text"
              >人才评定
              </router-link>
              <router-link
                to="/productService"
                class="text"
              >人才能力</router-link>
            </div>
            <div class="item">
              <div class="label">解决方案</div>
              <router-link
                to="/community"
                class="text"
              >
                社会招聘
              </router-link>
              <router-link
                to="/jobAdjustment"
                class="text"
                slot="reference"
              >
                岗位调整
              </router-link>
              <router-link
                to="/topManager"
                class="text"
                slot="reference"
              >
                中高管招聘
              </router-link>
              <router-link
                to="/solution"
                class="text"
                slot="reference"
              >
                内部选拔晋升
              </router-link>

            </div>
          </div>

          <div class="right">
            <el-popover placement="top">
              <div
                class="code-item"
                slot="reference"
              >
                <img
                  class="code-img"
                  src="../src/assets/img/cepingrukou.png"
                  alt=""
                >

              </div>
              <div class="code-img-box">
                <img
                  class="img"
                  src="../src/assets/img/code.png"
                  alt=""
                >
              </div>

            </el-popover>
            <el-popover placement="top">
              <div
                class="code-item"
                slot="reference"
              >
                <img
                  class="code-img"
                  src="../src/assets/img/huashuyungzh.png"
                  alt=""
                >

              </div>
              <div class="code-img-box">
                <img
                  class="img"
                  src="../src/assets/img/code-1.png"
                  alt=""
                >
              </div>

            </el-popover>
          </div>
        </div>
        <div class="copyright">
          <div class="lanel">
            <div class="lanel-text">Copyright © 2023 . All Rights Reserved. 广州华数云计算有限公司站</div>
            <div class="lanel-text">京ICP备16045432号-4</div>
            <div class="lanel-text">粤公网安备44011702000694号</div>
          </div>
        </div>
      </div>
    </footer>
    <CodeImg ref="openCodeImg"></CodeImg>
  </div>
</template>

<script>
import pageHeader from '@/components/pageHeader'
import CodeImg from '@/components/codeImg.vue';
export default {
  components: {
    pageHeader,
    CodeImg
  }
}
</script>

<style lang="less">
@import "./assets/css/reset.less";
@import "animate.css";

html,
body {
  height: 100%;
  margin: 0;
  background-color: #ffffff;
  color: #030303;
}
.scrollable::-webkit-scrollbar {
  display: none; /* 针对Webkit浏览器隐藏滚动条 */
}
/* 兼容IE和Edge */
.scrollable {
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}
#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
img,
a {
  display: block;
}
.page-wrap {
  max-width: 1440px;
  margin: 0 auto;
}
.page-title {
  font-size: 36px;
  font-weight: 530;
  color: #4b4e54;
  text-align: center;
}
/*设置宽度,轨道颜色*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #c6c6c6;
  /*可以设置轨道颜色*/
  padding-right: 2px;
}

/*滚动条*/
::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 10px;
}

.line-clamp-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.line-clamp-3 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.noData {
  padding-top: 75px;
  color: #5e6272;
  text-align: center;

  .emptyimg {
    width: 175px;
  }

  .empty-text {
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    margin-top: 2px;
  }
}

footer {
  position: relative;
  z-index: 99;

  .footetr-bac {
    width: 100%;
    height: 439px;
    background: url("@/assets/img/footer-bac.png") no-repeat;
    background-size: cover;
    text-align: center;
    padding-top: 90px;
    .foo-title {
      font-weight: 600;
      font-size: 37px;
      color: #4b4e54;
    }

    .foo-text {
      margin-top: 15px;
      font-size: 19px;
      color: #82888f;
    }

    .foo-btn {
      width: 166px;
      height: 48px;
      background: #3e75f7;
      border-radius: 24px;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
      line-height: 48px;
      margin: 60px auto 0 auto;
    }

    .foo-number {
      font-weight: 600;
      font-size: 24px;
      color: #3e75f7;
      line-height: 33px;
      margin-top: 24px;
    }
  }
  .footer {
    height: 434px;
    background: #4b4e54;
  }
  .h5-footer {
    display: none;
  }
  .footer-wrap {
    display: flex;
    justify-content: space-between;
    padding-top: 38px;
    padding-bottom: 77px;
    .left {
      display: flex;
      .item {
        margin-right: 130px;
        .label {
          font-weight: 500;
          font-size: 19px;
          color: #e0e2e6;
          padding-bottom: 10px;
        }

        .text {
          display: block;
          font-weight: 400;
          font-size: 13px;
          color: #abaeb2;
          padding: 0;
          margin: 0;
          margin-top: 14px;
          cursor: pointer;
        }
        .text:hover {
          text-decoration: underline; /* 鼠标悬停时添加下划线 */
        }
      }
    }

    .right {
      display: flex;
      .code-item {
        margin-left: 80px;
        .code-label {
          font-weight: 500;
          font-size: 19px;
          color: #e0e2e6;
          text-align: center;
        }

        .code-img {
          margin-top: 24px;
          width: 110px;
          height: 110px;
        }
      }
    }
  }

  .copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0px 1px 0px 0px #8c8c8c;
    padding-top: 27px;
    img {
      width: 167px;
    }

    .lanel {
      .lanel-text {
        font-weight: 400;
        font-size: 12px;
        color: #9fa4ab;
        margin-left: 32px;
      }
    }
  }
}

.el-button--primary {
  background: #00b5a3 !important;
  border-color: #00b5a3 !important;
}
/deep/.el-dialog {
  background: transparent;
  box-shadow: none;
}
@media screen and (max-width: 750px) {
  .page-title {
    font-size: calc(16 / 375 * 100vw);
    font-weight: 600;
    color: #1f232a;
    text-align: center;
  }

  footer {
    position: relative;
    z-index: 99;

    .footetr-bac {
      width: 100%;
      height: calc(273 / 375 * 100vw);
      background: url("@/assets/img/footer-bac.png") no-repeat;
      background-size: cover;
      text-align: center;
      padding-top: calc(46 / 375 * 100vw);
      .foo-title {
        font-weight: 600;
        font-size: calc(16 / 375 * 100vw);
        color: #4b4e54;
      }

      .foo-text {
        margin-top: 15px;
        font-size: calc(12 / 375 * 100vw);
        color: #82888f;
      }

      .foo-btn {
        width: 166px;
        height: 48px;
        background: #3e75f7;
        border-radius: 24px;
        font-weight: 500;
        font-size: calc(16 / 375 * 100vw);
        color: #ffffff;
        text-align: center;
        line-height: 48px;
        margin: 60px auto 0 auto;
      }

      .foo-number {
        font-weight: 600;
        font-size: calc(16 / 375 * 100vw);
        color: #3e75f7;
        line-height: 33px;
        margin-top: 24px;
      }
    }
    .footer {
      height: 434px;
      background: #4b4e54;
      display: none;
    }
    .h5-footer {
      display: block;
      background: #4b4e54;
      padding: calc(46 / 375 * 100vw) calc(24 / 375 * 100vw);
      .logo-img {
        width: calc(167 / 375 * 100vw);
      }
    }
    .footer-wrap {
      display: block;
      justify-content: space-between;
      padding-top: 38px;
      padding-bottom: 32px;
      .left {
        display: flex;
        justify-content: space-between;
        .item {
          margin-right: 10px;
          .label {
            font-weight: 500;
            font-size: calc(16 / 375 * 100vw);
            color: #e0e2e6;
            padding-bottom: 10px;
          }

          .text {
            display: block;
            font-weight: 400;
            font-size: calc(12 / 375 * 100vw);
            color: #abaeb2;
            padding: 0;
            margin: 0;
            margin-top: 14px;
            cursor: pointer;
          }
          .text:hover {
            text-decoration: underline; /* 鼠标悬停时添加下划线 */
          }
        }
      }

      .right {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        .code-item {
          margin: 0;

          .code-label {
            font-weight: 500;
            font-size: 19px;
            color: #e0e2e6;
            text-align: center;
          }

          .code-img {
            margin-top: 24px;
            width: calc(158 / 375 * 100vw);
            height: calc(50 / 375 * 100vw);
            object-fit: cover;
          }
        }
      }
    }

    .copyright {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: none;
      padding-top: 27px;

      img {
        width: 167px;
      }

      .lanel {
        .lanel-text {
          font-weight: 400;
          font-size: calc(12 / 375 * 100vw);
          color: #9fa4ab;
          line-height: calc(24 / 375 * 100vw);
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
  /deep/.el-popup-parent--hidden {
    padding-right: 0 !important;
  }
  /deep/.el-popover {
    border-radius: 12px;
  }
  .code-img-box {
    .img {
      width: 140px;
      height: 140px;
      margin: auto;
    }
  }
}
</style>
